import { Image, Link, Path, Rect, Svg, Text, View } from '@react-pdf/renderer'
import { tw } from '~/components/pdfs'
import { type Listing } from '~/models'
import {
  fmtPhoneNumber,
  getBrokerMemberships,
  transformImageUrl,
} from '~/utils'

function EmailIcon() {
  return (
    <Svg
      height="16"
      width="16"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLineCap="round"
      strokeLinejoin="round">
      <Rect width="20" height="14" x="2" y="4" rx="4" fill="none" />
      <Path d="m22 7-8.97 5.7a1.94 1.94 0 0 1-2.06 0L2 7" fill="none" />
    </Svg>
  )
}

function OfficeIcon() {
  return (
    <Svg
      width="16"
      height="16"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLineCap="round"
      strokeLinejoin="round">
      <Path
        fill="none"
        d="M22 16.92v3a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.5 19.5 0 0 1-6-6 19.79 19.79 0 0 1-3.07-8.67A2 2 0 0 1 4.11 2h3a2 2 0 0 1 2 1.72 12.84 12.84 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L8.09 9.91a16 16 0 0 0 6 6l1.27-1.27a2 2 0 0 1 2.11-.45 12.84 12.84 0 0 0 2.81.7A2 2 0 0 1 22 16.92z"
      />
    </Svg>
  )
}

function MobileIcon() {
  return (
    <Svg
      width="16"
      height="16"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLineCap="round"
      strokeLinejoin="round">
      <Rect fill="none" width="14" height="20" x="5" y="2" rx="2" ry="2" />
      <Path fill="black" stroke="black" d="M12 18h.01" />
    </Svg>
  )
}

export default function PdfListingBrokers({ listing }: { listing: Listing }) {
  return (
    <View style={tw('mt-4')} break>
      <Text style={tw('font-heading mb-4 text-lg leading-none')}>
        {listing.company.name}
      </Text>
      {listing.brokers.map((broker) => {
        if (broker.status === 'inactive') {
          return (
            <View key={broker._id} style={tw('mb-4')}>
              <Text style={tw('mb-1 text-base font-medium')}>
                {`${broker.firstName} ${broker.lastName}`}
              </Text>
              <Text style={tw('text-sm')}>
                Broker no longer with {listing.company.name}
              </Text>
            </View>
          )
        }
        return (
          <View key={broker._id} style={tw('mb-4 flex flex-col gap-2')}>
            <View style={tw('flex flex-row items-start gap-4')}>
              {broker.meta?.photo ? (
                <Image
                  style={tw('w-12 rounded-full')}
                  src={transformImageUrl(broker.meta?.photo?.thumbUrl)}
                />
              ) : (
                <View
                  style={tw(
                    'flex h-12 w-12 flex-row items-center justify-center rounded-full bg-gray-200'
                  )}>
                  <Text style={tw('mt-px text-base font-medium')}>
                    {[broker.firstName.slice(0, 1), broker.lastName.slice(0, 1)]
                      .join('')
                      .toUpperCase()}
                  </Text>
                </View>
              )}
              <View style={tw('flex flex-col gap-1')}>
                <View style={tw('flex flex-row')}>
                  <Text style={tw('text-base font-medium')}>
                    {`${broker.firstName} ${broker.lastName}`}
                  </Text>
                  {broker.meta?.memberships && (
                    <Text style={tw('ml-2 text-base text-muted-foreground')}>
                      {getBrokerMemberships(broker)}
                    </Text>
                  )}
                </View>
                {broker.meta?.title && (
                  <Text style={tw('text-sm text-muted')}>
                    {broker.meta.title}
                  </Text>
                )}
                {broker.email && (
                  <View style={tw('mt-1 flex flex-row')}>
                    <Text style={tw('w-4 text-sm')}>E:</Text>
                    <Link
                      style={tw('text-sm text-primary no-underline')}
                      href={`mailto:${broker.email}`}>
                      {broker.email}
                    </Link>
                  </View>
                )}
                {broker.meta?.office && (
                  <View style={tw('mt-1 flex flex-row')}>
                    <Text style={tw('w-4 text-sm')}>O:</Text>
                    <Link
                      style={tw('text-sm text-primary no-underline')}
                      href={`tel:${broker.meta?.office}`}>
                      {fmtPhoneNumber(broker.meta.office)}
                    </Link>
                  </View>
                )}
                {broker.meta?.mobile && (
                  <View style={tw('mt-1 flex flex-row')}>
                    <Text style={tw('w-4 text-sm')}>M:</Text>
                    <Link
                      style={tw('text-sm text-primary no-underline')}
                      href={`tel:${broker.meta?.mobile}`}>
                      {fmtPhoneNumber(broker.meta.mobile)}
                    </Link>
                  </View>
                )}
              </View>
            </View>
          </View>
        )
      })}
    </View>
  )
}
